<template>
  <v-container fluid class='report-card align-self-sm-stretch justify-space-around'>
    <v-row class='d-flex justify-center card-title'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between'>
        <span class='report-title'> {{ $t('TEMPO_MEDIO_ATENDIMENTO_OPERADOR_REPORT_TITLE') }} </span>
        <v-icon
          class='mr-4'
          @click='fetchWidgetContent'
        >
          mdi-reload
        </v-icon>
      </v-row>
      <v-col cols='12'>
        <v-divider />
      </v-col>
    </v-row>
    <v-row class='d-flex justify-center mt-2'>
      <v-card-text v-if='hasData'>
        <v-row align='center' justify='center'>
          <apexchart
            :key='chartKey'
            ref='chart'
            type='bar'
            width='500'
            :options='chartOptions'
            :series='series'
          />
        </v-row>
      </v-card-text>
      <span v-else>
        {{ $t('NO_DATA') }}
      </span>
    </v-row>
    <v-row
      class='report-footer'
    >
      <v-icon
        @click='openFilters'
      >
        mdi-filter-outline
      </v-icon>
    </v-row>
    <v-dialog
      v-model='filterDialog'
      fullscreen
      hide-overlay
      transition='dialog-bottom-transition'
    >
      <v-card>
        <Filters @onFilter='saveFilter' @applyFilters='fetchWidgetContent' @close='filterDialog = false' />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    components: {
      Filters: () => import('./Filters.vue'),
    },
    data () {
      return {
        reportName: 'Tempo médio de atendimento por operador',
        value: null,
        filterDialog: false,
        chartKey: 1,
        allOperators: [],
        operators: null,
        hasData: true,
        operatorFilters: null,
        chartOptions: {
          chart: {
            width: 500,
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          yaxis: {
            title: {
              text: this.$t('MINUTES'),
            },
          },
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
        series: [{
          name: this.$t('OVERALL'),
          data: [],
        }],
      };
    },
    mounted: function () {
      this.fetchWidgetContent();
    },
    methods: {
      async fetchWidgetContent () {
        this.cleanData();
        let operatorFilterId = [];
        if (this.operatorFilters && this.operatorFilters.length > 0) {
          if (this.operatorFilters[0].name === 'Todos') {
            operatorFilterId = [];
          } else {
            operatorFilterId = this.operatorFilters.map((o) => o.account_id);
          }
        }
        this.filterDialog = false;
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: {
              operator_filter: operatorFilterId.length > 0 ? `creator_id in (${operatorFilterId.join(',')})` : null,
            },
          },
        });
        const creators = await axios({
          url: '/account-get-all',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.allOperators = creators.data.list;
        this.operators = data && data.data ? data.data : null;
        if (this.operators.length > 0) {
          this.hasData = true;
          for (let i = 0; i < this.operators.length; i++) {
            const index = this.allOperators.findIndex((o) => o.account_id === this.operators[i].operator);
            if (index < 0) { return; }
            this.series[0].data.push(((parseInt(this.operators[i].total_time, 10) / parseInt(this.operators[i].records, 10)) / 60000).toFixed(2));
            this.chartOptions.labels.push(this.allOperators[index].name);
          }
        } else {
          this.hasData = false;
        }
        this.chartKey++;
      },
      cleanData () {
        this.allOperators = [];
        this.series = [{
          name: this.$t('OVERALL'),
          data: [],
        }];
        this.chartOptions.labels = [];
        this.operators = null;
      },
      removeWidget (id) {
        document.removeItem(id);
      },
      saveFilter (filter) {
        if (filter.selectedOperators) {
          this.operatorFilters = filter.selectedOperators;
        }
      },
      openFilters () {
        this.filterDialog = true;
      },
    },
  };
</script>
<style>
  html {
   overflow: hidden;
  }
  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
  }
  .content-value {
    margin-top: 20vh !important;
  }
  .card-title {
    min-height: 30%;
  }
  .report-footer {
    position: absolute;
    bottom: 10vh;
    left: 2vw;
  }
</style>
